import './Error404.scss'
import BotonHome from '../BotonHome/BotonHome';

function Error404() {
    return (
       <div className="error404-content">
          <p className="error404-texto">No hay nada aqui carnal &#128065;</p>
         <BotonHome></BotonHome>
       </div>
    );
}
 
export default Error404;