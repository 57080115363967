import './BotonHome.scss';
import { LinkContainer } from 'react-router-bootstrap'
import svgHome from "../../assets/svg/home.svg";

function BotonHome() {
  return (
    <LinkContainer to="/">
        <img src={svgHome} alt="home" className="botonhome-svg-home"></img>
    </LinkContainer>
  );
}

export default BotonHome;
