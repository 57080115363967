import './App.scss';

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Home from './components/Home/Home';
import InfoPinturas from './components/InfoPinturas/InfoPinturas';
import Error404 from './components/Error404/Error404';
// import CuentaNavidad from './components/CuentaNavidad/CuentaNavidad';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAD7XXf9CB8tGO4YRo6EPvgODEkMjk5wZc",
  authDomain: "wero-lopez.firebaseapp.com",
  databaseURL: "https://wero-lopez.firebaseio.com",
  projectId: "wero-lopez",
  storageBucket: "wero-lopez.appspot.com",
  messagingSenderId: "467011536352",
  appId: "1:467011536352:web:1028d12fb22b3aa9009785",
  measurementId: "G-R1C1B66NFH"
};

class App extends Component {
  render() {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    
    return (      
       <BrowserRouter>
        <div>
          <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/infopinturas" component={InfoPinturas}/>
            {/* <Route path="/cuentanavidad" component={CuentaNavidad}/>*/}
            <Route component={Error404}/>
          </Switch>
        </div> 
      </BrowserRouter>
    );
  }
}

export default App;
