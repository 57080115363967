import './InfoPinturas.scss';
import arnold from '../../assets/imagenes/arnold.jpg';
import weropinturas from '../../assets/imagenes/weropinturas.jpg';
import BotonHome from '../BotonHome/BotonHome';

function InfoPinturas() {
  return (
    <div className="infop-content">
      
      <h1 className="infop-titulo">Wero López Art</h1>
      
      <p className="infop-fecha">
        Marzo 2022
      </p>
      <p className="infop-comisiones">
        &#10060; Comisiones cerradas &#10060;
        {/*&#128994; Comisiones abiertas &#128994;*/}
      </p>
      <div className="infop-div-arnold">
        <img src={arnold} alt="arnold" className="infop-img-arnold"></img>
        <div className="infop-border-arnold"></div>
      </div>

      <p className="infop-subtitulo">
        Hola!
      </p>
      <div className="infop-div-texto">
        <p className="infop-texto">
          Mi nombre es Eduardo pero todo mundo me dice Wero, 
          en mi instagram de fotos podrán ver porque. 
          Soy un wey de Sonora México que ha decidido intentar vivir del arte. &#x1F335; &#127474;&#127485;
        </p>
        <ul>
          <li className="infop-texto-bullet">
            Sobre las pinturas
          </li>
        </ul>
        <p className="infop-texto">
          Hago comisiones de pinturas al óleo sobre lienzo de algodón en 
          bastidores de 30 x 40 cm, con un grosor de 2 cm. 
          No suelo vender pinturas en bastidores más grandes, 
          sin embargo puedo hacerlo, lo que incrementa el tiempo y el costo. 
          De momento no hago comisiones de retratos ni imágenes muy complejas, 
          sigo practicando para poder entregar un mejor trabajo, espero poder 
          hacerlo próximamente. Por mi sanidad, no acepto pedidos de pinturas que ya he hecho. 
          Mis pinturas no incluyen marco, ya que eso es 
          un trabajo de carpintería, que no manejo. 
        </p>
        <ul>
          <li className="infop-texto-bullet">
            Sobre el proceso de espera
          </li>
        </ul>
        <p className="infop-texto">
          Entenderá que una pintura al óleo es un proceso tardado, 
          por lo que pido que no desespere. El proceso desde que inicio 
          una pintura hasta que llega a su casa tarda cerca de un mes. 
          Tardo cinco días en promedio por pintura, más dos semanas que 
          tarda en secarse, con un envío de 3 a 5 días hábiles. De momento 
          solo hago envíos en México, espero hacer envíos internacionales 
          en el futuro. Hay una lista de espera respecto a las pinturas 
          que tengo pendientes, le haré saber aproximadamente cuándo 
          iniciaría y cuando podría llegar su pintura.
        </p>
        <ul>
          <li className="infop-texto-bullet">
            Sobre el paquete
          </li>
        </ul>
        <p className="infop-texto">
          Las pinturas van envueltas en una capa de papel kraft de 125 gr 
          libre de ácido para evitar que el óleo se despinte. Se le ponen 
          esquineros de cartón para evitar que el bastidor se golpee. Después 
          va una capa doble de papel de burbuja como protección. De ahí va 
          otra capa de papel kraft como envoltorio. Y termina enrollada con 
          cinta frágil para indicar a los paqueteros que tengan cuidado. 
          Se incluye dentro un sticker de cortesía. El paquete tarda en 
          llegar de 3 a 5 días hábiles por Estafeta.
        </p>
        <ul>
          <li className="infop-texto-bullet">
            Sobre el costo
          </li>
        </ul>
        <p className="infop-texto">
          Las pinturas de 30 x 40 cm tienen un costo base de $1,500.00, con 
          envío incluído. El precio puede aumentar dependiendo la complejidad.
          Puedo cotizarle una pintura más grande si lo desea, hasta 80x80 cm.
          Los pagos se hacen por transferencia bancaria, al comunicarse conmigo 
          le haré saber la cuenta. Aparta su lugar en la cola con un anticipo 
          del 50%, en ese momento le diré la fecha aproximada en la que iniciaré
          su pintura. Al terminar le haré saber con una foto y le pediría el 
          resto del pago y su dirección para poder hacer el envío. 
        </p>
        <ul>
          <li className="infop-texto-bullet">
            Sobre reembolsos
          </li>
        </ul>
        <p className="infop-texto">
          Las pinturas van muy bien protegidas en el paquete, jamás ha pasado que 
          una pintura se dañe durante el envío. Entenderá que si esto ocurre está 
          fuera de mi control, así como entiendo que también está fuera del suyo, 
          por lo que puedo hacer un reembolso del 60% del costo en caso de que suceda. 
        </p>
        <p className="infop-firma1">
          Muchas gracias por leer esta info :)
        </p>
        <p className="infop-firma2">
          Wero López
        </p>
      </div>

      <img src={weropinturas} alt="weropinturas" className="infop-img-weropinturas"></img>
      
      <BotonHome></BotonHome>

      <div className="infop-fondo"></div>

    </div>
  );
}

export default InfoPinturas;
