import './Home.scss';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap'

function Home() {
  return (
    <div className="home-bg">

      <div className="home-content">

        <h1 className="home-titulo">Wero López</h1>
          <p className="home-subtitulo">
            Busco dentro de mi, por si me encuentro.
          </p>

          <LinkContainer to="/infopinturas">
            <Button variant="outline-warning" className="home-boton-pinturas">Info de las pinturas</Button>
          </LinkContainer>
        
          <a target="_blank" rel="noreferrer" href="https://instagram.com/werolopez">
            <Button variant="outline-warning" className="home-boton">Este soy yo</Button>
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/werolopez.art/">
            <Button variant="outline-warning" className="home-boton">Mi insta</Button>
          </a>
          {/*<a target="_blank" rel="noreferrer" href="https://instagram.com/wero.raw">
            <Button variant="outline-warning" className="home-boton">Fotos que foteo</Button>
          </a>*/}
          <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@werolopez_">
            <Button variant="outline-warning" className="home-boton">Mi tiktok</Button>
          </a>
          {/*<a target="_blank" rel="noreferrer" href="https://youtu.be/4Y4dntIBiPI">
            <Button variant="outline-warning" className="home-boton">Kirbo</Button>
          </a>*/}
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=_YWhdTsTW_U">
            <Button variant="outline-warning" className="home-boton">Un cumbion</Button>
          </a>
          {/*<LinkContainer to="/cuentanavidad">
            <Button variant="outline-warning" className="home-boton">Cuenta regresvia para Navidad 🎄</Button>
          </LinkContainer>*/}
          <a target="_blank" rel="noreferrer" href="https://ko-fi.com/werolopez">
            <Button variant="outline-warning" className="home-boton">Un cafecito pal shuerk</Button>
          </a>

          <p className="home-contacto1">
            Para contacto:
          </p>
          <p className="home-contacto2">
            Mándame DM a insta :)
          </p>
          
      </div>
      
    </div>
  );
}

export default Home;